import React from 'react';
import { Autocomplete, TextField, Tooltip, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PrimaryColorLoader from "../../PrimaryColorLoader";


const ValidationSelector = ({ uams, selectedUamId, setSelectedUamId, loading}) => {
  const currentIndex = uams.findIndex(uam => uam.id === selectedUamId);

  const handleChange = (event, newValue) => {
    if (newValue) {
      setSelectedUamId(newValue.id);
    } else {
      setSelectedUamId(null);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setSelectedUamId(uams[currentIndex - 1].id);
    }
  };

  const handleNext = () => {
    if (currentIndex < uams.length - 1) {
      setSelectedUamId(uams[currentIndex + 1].id);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Tooltip title="Previous">
        {/* Wrap IconButton in a <span> so you can properly disable it */}
        <span>
          <IconButton onClick={handlePrev} disabled={currentIndex <= 0}>
            <ArrowBackIcon />
          </IconButton>
        </span>
      </Tooltip>

      <Autocomplete
        id="uam-selector"
        options={uams}
        getOptionLabel={(option) => `(${option.status}) - ${option.utility_company_name} - ${option.acct_num} - ${option.meter_num || "No Meter #"} - ${option.display_name} - ${option.association_type !== "STANDARD" ? `${option.association_type} -` : ""}${option.id}`}
        style={{ width: 800, margin: "0 16px" }}
        onChange={handleChange}
        value={uams.find(uam => uam.id === selectedUamId) || null}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select UAM"
            variant="outlined"
          />
        )}
      />


      <Tooltip title="Next">
        <span>
          <IconButton
            onClick={handleNext}
            disabled={currentIndex === -1 || currentIndex >= uams.length - 1}
          >
            <ArrowForwardIcon />
          </IconButton>
        </span>
      </Tooltip>
      <PrimaryColorLoader
        style={{opacity: loading ? 1 : 0}}
      /> 
    </div>
  );
};

export default ValidationSelector;
