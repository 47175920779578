import {useState} from 'react';
import {a11yProps} from "../../helpers/a11y"
import { Typography, AppBar, Tabs, Tab } from "@material-ui/core"
import DirectionsBoat from "@material-ui/icons/DirectionsBoat"

export default function ValidationAppBar({validationTabIndex, setValidationTabIndex}) {

  const boxShadow = "0px 3px 4px -1px rgba(0,0,0,0.2)"

  return (
    <AppBar position="static" color="default" style={{boxShadow}}>
      <Typography variant="h2">Invoyage <DirectionsBoat style={{fontSize: 40}} /> Review</Typography>
      <Tabs   
          centered 
          indicatorColor="primary" 
          value={validationTabIndex} 
          onChange={(e, val) => setValidationTabIndex(val)}>
          <Tab label="Daily" {...a11yProps(0)} />
          <Tab label="Browse All" {...a11yProps(1)} />
      </Tabs>
    </AppBar>
  )
}