import { createMuiTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import { darkenHexColor } from '../components/helpers/color-helpers';
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: orange[600],
      contrastText: '#fff',
    },
    secondary: {
      main: orange[600],
    },
  },
});

const overrides = {
  invoyage: {
    MuiTableCell: {
      root: {
        fontSize: '1rem', // Set font size for all table cells
      },
    },
  },
}

export const buildTheme = (params={}) => {
  const darkMode = params.dark || localStorage.getItem("dark-mode") || (params.prefersDarkMode ? "dark" : "light");
  const themeColor = params.color || localStorage.getItem("theme-color") || "#2f4050";

  return createMuiTheme({
    palette: {
      background: {
        paper: darkMode === 'dark' ? '#121212' : '#ffffff',
      },
      mode: darkMode,
      primary: {
        main: themeColor,
        contrastText: '#fff',
      },
      secondary: {
        main:  themeColor,
      },
    },
    overrides: overrides.invoyage
  })
}

export const adminTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2f4050",
      contrastText: '#fff',
    },
    secondary: {
      main: "#2f4050",
    },
  },
});
export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: orange[600],
      contrastText: '#fff',
    },
    secondary: {
      main: orange[600],
    },
  },
});
