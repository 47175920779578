import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Container,
} from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import axiosAuth from '../../../utils/axiosWithAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1400,
    margin: '0 auto',
  },
  formGroup: {
    display: 'flex',
    gap: '1rem',
    marginBottom: '1rem',
    // MUI v4 don’t support gap natively, but we can hack it:
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  formControl: {
    minWidth: 120,
  },
}));

function getColorForUser(user) {
  // For example, we can do a simple hash from the email string
  let hash = 0;
  for (let i = 0; i < user.length; i++) {
    hash = user.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash; // convert to 32bit integer
  }
  // then map that to an HSL color
  const hue = Math.abs(hash) % 360;
  return `hsl(${hue}, 60%, 60%)`;
}

function LeaderboardChart() {

  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // getMonth() is zero-based, adding 1 for human-readable format
  const [month, setMonth] = useState(currentMonth.toString());
  const [year, setYear] = useState(currentYear.toString());
  const [userId, setUserId] = useState('all');
  const [leaderboardData, setLeaderboardData] = useState([]);

  // We can fetch a list of users if you want them selectable in a dropdown
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // This be a simple fetch to get all them users for the dropdown
    // Adjust as needed for your actual route
    axiosAuth().get(`/admin/scraped_bill_queue/users`)
      .then((res) => {
        setUsers(res.data.users);
      })
      .catch((err) => {
        console.error('Error fetching users:', err);
      });
  }, []);

  useEffect(() => {
    // Re-run query whenever month, year, or userId changes
    fetchLeaderboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, userId]);

  function fetchLeaderboardData() {
    const params = new URLSearchParams();
    if (month && year) {
      params.append('month', month);
      params.append('year', year);
    }
    if (userId && userId != "all") {
      params.append('userId', userId);
    }

    axiosAuth().get(`/admin/scraped_bill_queue/leaderboard?${params.toString()}`)
      .then((res) => {
        setLeaderboardData(res.data.leaderboardData);
      })
      .catch((err) => {
        console.error('Error fetching leaderboard data:', err);
      });
  }

  const allMonths = Array.from(
    new Set(leaderboardData.map((item) => item.month))
  ).sort();

  // Now group the data by user email
  // so we can build multiple datasets - one for each user
  const dataByUser = {};
  leaderboardData.forEach((row) => {
    const { email, month: m, total_uploads } = row;
    if (!dataByUser[email]) dataByUser[email] = {};
    dataByUser[email][m] = total_uploads;
  });

  // Build that dataset array
  // each user = 1 dataset
  const datasets = Object.keys(dataByUser).map((email) => {
    return {
      label: !email || email == "null" ? "System" : email,
      data: allMonths.map((m) => dataByUser[email][m] || 0),
      backgroundColor: getColorForUser(email),
    };
  });

  const chartData = {
    labels: allMonths,
    datasets,
  };

  // Basic chart options
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  return (
    <Container className={classes.root}>
      <Typography variant="h5" gutterBottom>
        Intake Tracking
      </Typography>

      <div className={classes.formGroup}>
        {/* Month Selector */}
        <FormControl className={classes.formControl}>
          <InputLabel id="month-label">Month</InputLabel>
          <Select
            labelId="month-label"
            id="month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          >
            <MenuItem value="">
              <em>All Months</em>
            </MenuItem>
            {[...Array(12)].map((_, i) => {
              const m = i + 1;
              return (
                <MenuItem key={m} value={m}>
                  {m}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {/* Year Selector */}
        <FormControl className={classes.formControl}>
          <InputLabel id="year-label">Year</InputLabel>
          <Select
            labelId="year-label"
            id="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            <MenuItem value="">
              <em>All Years</em>
            </MenuItem>
            {[...Array(new Date().getFullYear() - 2022)].map((_, index) => (
              <MenuItem key={index} value={2023 + index}>
                {2023 + index}
              </MenuItem>
            ))}
            {/* Add more or dynamically generate */}
          </Select>
        </FormControl>

        {/* User Selector */}
        <FormControl className={classes.formControl}>
          <InputLabel id="user-label">User</InputLabel>
          <Select
            labelId="user-label"
            id="userId"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          >
            <MenuItem value="all">
              <em>All Users</em>
            </MenuItem>
            {users.map((u) => (
              <MenuItem key={u.id} value={u.id}>
                {u.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <Bar data={chartData} options={chartOptions} />
    </Container>
  );
}

export default LeaderboardChart;