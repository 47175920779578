export function darkenHexColor(hex, percent) {
  // Ensure the hex string starts with "#"
  if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
  }

  // Convert hex to r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  // Darken each color component
  r = parseInt(r * (1 - percent/100));
  g = parseInt(g * (1 - percent/100));
  b = parseInt(b * (1 - percent/100));

  // Ensure values are within 0-255 range
  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  // Convert back to hex and return
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
}

// Example usage:
const originalColor = "#3498db"; // Some blue color
const darkenedColor = darkenHexColor(originalColor, 20);
console.log(darkenedColor);  // Outputs the darkened color

export const epColors = [ 
  "#de3163", // vibrant yellow
  "#d0ddb7", // cerise
  "#0077FF", // azure
  "#00a693",  // persian green
  "#c2b280", // ecru
  "#cc83cd", // soft purple
  "#006d6f", // salmon pink
  "#e2758f", // muted pink
  "#F2C45A", // mustard yellow // 2024
  "#7A7F5B", // deep purple
  "#4d1618", // maroon
  "#243238", // dark slate gray
  "#54B2D2", // sky blue
  "#8B3F9F", // olive green // 2024
  "#8E44AD", // indigo
  "#FF99CC", // pastel pink
  "#33B5E5", // bright blue
  "#FF69B4", // hot pink
  "#0099CC", // teal blue
  "#66CC00", // lime green
  "#FFA07A", // light salmon
  "#8B0A1A", // dark red
  "#45B3FA", // cerulean
  "#F8E231", // lemon yellow
  "#C0392B", // crimson red
  "#d0ddb7", // pale olive
  "#FFC107", // amber
  "#483c32", // taupe
  "#9B59B6"  // amethyst
]