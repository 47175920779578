import { useState, useContext } from "react";
import { TextField, Typography, Button, MenuItem, Select, FormControl, InputLabel, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import TextractContext from "../../../contexts/TextractContext";

export default function SplitRules({ splitRules, handleSplitRuleChange, handleNewSplitRule, handleDeleteSplitRule }) {
  const { selectedConfiguration } = useContext(TextractContext);

  return (
    <>
      {splitRules && !!splitRules.length && (
        <>
          <Typography variant="h5">Split Rules</Typography>
          {splitRules.map((rule, idx) => (
            <div key={rule.id} style={{ marginBottom: "1.5em", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="subtitle1">{`Rule ${idx + 1}`}</Typography>

              {/* Rule Type */}
              <FormControl margin="normal" style={{ width: 500 }}>
                <InputLabel>Rule Type</InputLabel>
                <Select
                  value={rule.rule_type || ""}
                  onChange={(e) => handleSplitRuleChange(e, rule.id)}
                  name={`rule_type-${rule.id}`}
                >
                  <MenuItem value="start_end">Start to End</MenuItem>
                  <MenuItem value="cut_after">Cut After</MenuItem>
                  <MenuItem value="n_lines_after">N Lines After</MenuItem>
                </Select>
              </FormControl>

              {/* Start Condition */}
              <TextField
                InputLabelProps={{ shrink: true }}
                margin="normal"
                style={{ width: 500 }}
                label="Start Condition"
                name={`start_condition-${rule.id}`}
                value={rule.start_condition || ""}
                onChange={(e) => handleSplitRuleChange(e, rule.id)}
              />

              {/* End Condition (only for 'start_end') */}
              {rule.rule_type === "start_end" && (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  style={{ width: 500 }}
                  label="End Condition"
                  name={`end_condition-${rule.id}`}
                  value={rule.end_condition || ""}
                  onChange={(e) => handleSplitRuleChange(e, rule.id)}
                />
              )}

              {/* Line Count (only for 'n_lines_after') */}
              {rule.rule_type === "n_lines_after" && (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  style={{ width: 500 }}
                  label="Line Count"
                  name={`line_count-${rule.id}`}
                  type="number"
                  value={rule.line_count || ""}
                  onChange={(e) => handleSplitRuleChange(e, rule.id)}
                />
              )}
              <IconButton
                color="secondary"
                onClick={() => handleDeleteSplitRule(rule)} // Call delete function
                aria-label={`Delete query ${idx + 1}`}
              >
              <DeleteIcon />
            </IconButton>
            </div>
          ))}
        </>
      )}
      {/* Add New Rule */}
      <Button onClick={() => handleNewSplitRule(selectedConfiguration)}>New Rule +</Button>
    </>
  );
}