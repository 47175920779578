import { Paper,MenuItem, Select, TextField, Typography, FormControl, InputLabel, IconButton, makeStyles  } from "@material-ui/core";
import InvoyageTooltip from "../../InvoyageTooltip";
import { useContext, useState, useEffect } from "react";
import TextractContext from "../../../../../contexts/TextractContext";
import GPTBillContext from "../../../../../contexts/GPTBillContext";
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { UNFOCUSABLE_PROPS } from "../../../../helpers/constants";

const useStyles = makeStyles((theme) => ({
  select: {
    padding: "4px 8px",
    fontSize: "0.875rem",
  },
  menu: {
    maxHeight: 200, // Optional: Restrict dropdown height
    fontSize: "0.875rem",
  },
  headerDiv: {
    boxShadow: 'none',
    border: 'none',
    paddingLeft: 0,
    marginBottom: 16
  },
  }
));



export default ({uam, group}) => {
  const {uams,selectedTextractBill} = useContext(TextractContext)
  const {gptBill, setGptBill} = useContext(GPTBillContext)
  const classes = useStyles();

  const [initialDates, setInitialDates] = useState({
    start: null,
    end: null
  })

  useEffect(_ => {
    const lineItems = [...gptBill.charges, ...gptBill.usages].filter(li => li.utility_account_meter_id == uam.id)
    if (!lineItems.length) return []
    
    const initialStart = [...lineItems].filter(li => li.applicable && li.start_date).map(li => li.start_date).sort()[0]
    const initialEnd = [...lineItems].filter(li => li.applicable && li.end_date).map(li => li.end_date).sort().pop()
    setInitialDates({
      start: initialStart ? initialStart.split("T")[0] : null,
      end: initialEnd ? initialEnd.split("T")[0] : null
    })
  }, [])

  function handleChange(e) {
    
    const traitToEdit = e.target.name;

    setGptBill(prevState => {
      const newCharges = [...prevState.charges]
      const newUsages = [...prevState.usages]
    
      newUsages.forEach(li => {
        if (li.utility_account_meter_id == uam.id) {
          li[traitToEdit] = e.target.value
        }
      })
      newCharges.forEach(li => {
        if (li.utility_account_meter_id == uam.id) {
          li[traitToEdit] = e.target.value
        }
      })
      return {
        ...prevState,
        charges: newCharges,
        usages: newUsages
      }
    })
  }

  function handleToggleAllApplicable(value) {
    setGptBill(prevState => {
      const newCharges = [...prevState.charges]
      const newUsages = [...prevState.usages]
    
      newUsages.forEach(li => {
        if (li.utility_account_meter_id == uam.id) {
          li["applicable"] = value
        }
      })
      newCharges.forEach(li => {
        if (li.utility_account_meter_id == uam.id) {
          li["applicable"] = value
        }
      })
      return {
        ...prevState,
        charges: newCharges,
        usages: newUsages
      }
    })
  }
  
  return (
<div 
  key={initialDates.start || "key-placeholder-dates"} 
  elevation={5} 
  style={{ marginBottom: 8, padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
  className={classes.headerPaper}
>
  <Typography 
    style={{ fontWeight: 'bold', marginLeft: 8, flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} 
    align='left' 
    variant='h5'
  >
    {uam.utility_company_name} - {uam.acct_num} - {uam.meter_num || uam.um_meter_num || "No Meter #"} - {uam.display_name} - {uam.association_type}
  </Typography>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ display: 'flex', marginRight: 8 }}>
      <InvoyageTooltip title={selectedTextractBill.processed ? "" : "Deselect All"} placement="left">
        <IconButton 
          tabIndex={-1} 
          color='primary' 
          disabled={selectedTextractBill.processed} 
          onClick={() => handleToggleAllApplicable(false)}
        >
          <CheckBoxOutlineBlank />
        </IconButton>
      </InvoyageTooltip>
      <InvoyageTooltip title={selectedTextractBill.processed ? "" : "Select All"} placement="left">
        <IconButton 
          tabIndex={-1} 
          color='primary' 
          disabled={selectedTextractBill.processed} 
          onClick={() => handleToggleAllApplicable(true)}
        >
          <CheckBox />
        </IconButton>
      </InvoyageTooltip>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <TextField 
        onChange={handleChange}
        type="date"
        name="start_date"
        label='Start Date'
        defaultValue={initialDates.start || null}
        disabled={selectedTextractBill.processed}
        InputLabelProps={{ shrink: true }}
        inputProps={{
          style: { padding: '8px', fontSize: '0.875rem' },
        }}
      />
      <TextField 
        onChange={handleChange}
        type="date"
        name="end_date"
        label='End Date'
        defaultValue={initialDates.end || null}
        disabled={selectedTextractBill.processed}
        InputLabelProps={{ shrink: true }}
        inputProps={{
          style: { padding: '8px', fontSize: '0.875rem' },
        }}
      />
      <FormControl variant="outlined" style={{ minWidth: '180px' }}>
        <Select
          fullWidth
          variant='outlined'
          onChange={handleChange}
          name='utility_account_meter_id'
          value={uam.id}
          disabled={selectedTextractBill.processed}
          classes={{
            root: classes.select, // Applies to the root of the Select component
          }}
          MenuProps={{
            classes: { paper: classes.menu }, // Applies styles to the dropdown menu
          }}
          inputProps={{
            id: `utility_account_meter_id_${uam.id}`,
            tabIndex: -1,
          }}
          style={{ padding: '4px 8px', fontSize: '0.875rem' }}
        >
          {uams.map(uam => (
            <MenuItem key={uam.id} value={uam.id}>
              {uam.meter_num || uam.um_meter_num} - {uam.display_name} {!!(uam.association_type === "STANDARD") && `(${uam.association_type})`} {!(uam.status === "Active") && `(${uam.status.toUpperCase()})`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  </div>
</div>
  )
}