import {useState} from 'react';
import {a11yProps} from "../../helpers/a11y"
import { Typography, AppBar, Tabs, Tab } from "@material-ui/core"
import DirectionsBoat from "@material-ui/icons/DirectionsBoat"

export default function IntakeAppBar({queueTabIndex, setQueueTabIndex}) {

  const boxShadow = "0px 3px 4px -1px rgba(0,0,0,0.2)"

  return (
    <AppBar position="static" color="default" style={{boxShadow}}>
    <Typography variant="h2">Invoyage <DirectionsBoat style={{fontSize: 40}} /> Intake</Typography>
        <Tabs   
            centered 
            indicatorColor="primary" 
            value={queueTabIndex} 
            onChange={(e, val) => setQueueTabIndex(val)}>
            <Tab label="All Manual" {...a11yProps(0)} />
            <Tab label="Open" {...a11yProps(1)} />
            <Tab label="Checked/Follow Up" {...a11yProps(2)} />
            <Tab label="Scraped" {...a11yProps(3)} />
            <Tab label="ARDEM" {...a11yProps(4)} />
            <Tab label="Scraper Monitoring" {...a11yProps(5)} />
            <Tab label="Leaderboard" {...a11yProps(6)} />
        </Tabs>
    </AppBar>
  
  )
}