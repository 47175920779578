import { Paper, Typography, Button, TextField, Link, Fade, IconButton } from "@material-ui/core";
import { useContext, useState, useEffect, useRef } from "react";
import IntakeContext from "../../../contexts/IntakeContext";
import TextractUploader from "../TextractUploader";
import TextractContext from "../../../contexts/TextractContext";
import { useIntakeAccounts } from '../../../contexts/AccountContext';
import usePrevious from "../../../hooks/usePrevious"
import { getFormattedDateUTC } from "../../helpers/date-helpers";
import { Launch } from "@material-ui/icons";
import { copyToClipboard } from "../../helpers/string-helpers"
import FileCopyIcon from '@material-ui/icons/FileCopy';


export default function IntakeCard({account, setAccount, getNextRowId, selectRow, open, fetchParams}) {
  const [visible, setVisible] = useState(true);
  const [cardData, setCardData] = useState(account);
  const {setSnackbar} = useContext(TextractContext)
  const prevAccountId = usePrevious(account?.id);
  const { accounts, markAsChecked, handleFollowUp } = useIntakeAccounts();

  useEffect(() => {
    if ((account || prevAccountId) && (account && account.id) !== prevAccountId) {
      setVisible(false);

      const timeoutId = setTimeout(() => {
        setCardData(account);
        setVisible(true);
      }, 200);

      return () => clearTimeout(timeoutId);
    } else {
      setCardData(account)
    }
  }, [account]);

  const setSnackbarCopyMessage = assetName => setSnackbar({message: `Copied ${assetName}`, severity: "success",  loader: null })

  return (
    <Paper style={{width: '98.5%', minHeight: 400, margin: '10px auto', padding: 15 }} elevation={5}>
      <Fade in={visible} timeout={200}>
        {!!cardData
        ? <div className={`intake-card-container`} key={cardData.id}>
            <div className="intake-card-title-bar">
              <div style={{textAlign: "left"}}>
                <Typography variant='h3' style={{marginTop: 0}}>
                  {cardData.Utility} - #{cardData.acct_num}<IconButton color='primary' onClick={() => {setSnackbarCopyMessage("Account #") ; copyToClipboard(cardData.acct_num || "")}}>
                    <FileCopyIcon color='primary' />
                  </IconButton>
                  {cardData.invoice_requires_manual_processing && <span title="This invoice requires manual processing. See the most recent bill for reference.">&nbsp;🛠️</span>}
                </Typography>
                <Typography variant='h4' style={{marginTop: 20}}>
                  <Link href={cardData.online_access_url} target="_blank">
                    {cardData.online_access_url || ""}
                  </Link>
                </Typography>
                <Typography variant='h4' style={{marginTop: 20, display: 'flex', alignItems: 'center'}}>
                  Username: {cardData.online_access_username}
                  <IconButton color='primary' onClick={() => {setSnackbarCopyMessage("username") ; copyToClipboard(cardData.online_access_username || "")}}>
                    <FileCopyIcon color='primary' />
                  </IconButton>
                </Typography>
                {cardData.online_access_password && (
                  <Typography variant='h4' style={{marginTop: 20, display: 'flex', alignItems: 'center'}}>
                    Password: {cardData.online_access_password}
                    <IconButton color='primary' onClick={() => {setSnackbarCopyMessage("password") ; copyToClipboard(cardData.online_access_password)}}>
                      <FileCopyIcon color='primary' />
                    </IconButton>
                  </Typography>
                )}
                {cardData["Locating Instructions"] && <Typography variant='h6' style={{marginTop: 20}}>Locating Instructions: {cardData["Locating Instructions"]}</Typography>}
                {cardData["2-Factor Notes"] && <Typography variant='h6' style={{marginTop: 20}}>2-Factor Notes: {cardData["2-Factor Notes"]}</Typography>}
              </div>
            
              <div style={{marginTop: -7}}>
                <div style={{display:'flex', justifyContent: 'flex-end', alignItems: 'flex-start'}}>
                  {cardData.latest_utility_bill_id && 
                    <div style={{display: 'flex', flexDirection: 'row'}}>  
                      <a target="_blank" href={`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_accounts/${cardData.utility_account_id}/utility_bills/${cardData.latest_utility_bill_id}/source`}>
                        <Button variant="contained">View Most Recent Invoice&nbsp;<Launch/></Button>   
                      </a>  
                    </div>
                  }

                  <Button onClick={_ => setAccount(null)}>Close X</Button>
                </div>
              </div>
            </div>
            <div className="intake-card-controls" style={{marginTop: 15}}>
              <Button style={{height: 55}} variant='outlined' onClick={_ => {  markAsChecked(account, fetchParams, !open ? null : getNextRowId() ? _ => selectRow(getNextRowId()) : _ => setAccount(null)) }}>Mark as Checked</Button>
              <div className="follow-up-controls" style={{display: 'flex', flexDirection: 'column'}}>

                <TextField
                  id="date"
                  label="Follow Up On"
                  type="date"
                  value={cardData.online_follow_up_date ? `${cardData.online_follow_up_date}`.split("T")[0] : ""}
                  defaultValue={cardData.online_follow_up_date ? cardData.online_follow_up_date.split("T")[0] : ""}
                  onChange={e => setCardData({...cardData, online_follow_up_date: e.target.value})}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button 
                  style={{marginTop: 4}}
                  variant='outlined'  
                  onClick={_ => handleFollowUp(account, cardData.online_follow_up_date, open, fetchParams, !open ? null : getNextRowId() ? _ => selectRow(getNextRowId()) : _ => setAccount(null))}
                >
                    Apply Follow Up Date
                </Button>
              </div>
              {account && !!account.online_follow_up_date && <span style={{marginTop: 15, cursor: 'pointer'}} title="Remove Follow-up date" onClick={_ => handleFollowUp(account, null, open, fetchParams)}>❌</span>}
              <TextractUploader utility_company_id={account && account.utility_company_id} account={account} selectNextRow={!open ? null : getNextRowId() ? _ => selectRow(getNextRowId()) : _ => setAccount(null)} fetchParams={fetchParams} setSnackbar={setSnackbar} />
            </div>
            <div className="intake-card-bottom-row">
              <div style={{textAlign: 'left'}}>
                <Typography variant='p' align='left'>
                  Latest Invoice Date: {cardData.latest_invoice_date ? getFormattedDateUTC(cardData.latest_invoice_date) : "None"}
                  <br/>
                  Billing Status: {cardData.billing_status}
                </Typography>
              </div>
              <div>
              Bottom Right Corner

              </div>
            </div>
          </div>
        : <Typography variant='h1' style={{paddingTop: 140}}>Select an account</Typography>}
      </Fade>
    </Paper>
  )
}