import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import { epColors } from '../../helpers/color-helpers';

// MUI v4 makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    maxWidth: 2000,
    minWidth: 1600
  },
}));

const getDateRange= row => {
  const startDate = new Date(row.start_date).toLocaleDateString('en-US', { timeZone: 'UTC' });
  const endDate = new Date(row.end_date).toLocaleDateString('en-US', { timeZone: 'UTC' });
  return `${startDate}-${endDate}`;
}

export default function ValidationChart({ dataRows = [], uam }) {
  const classes = useStyles();

  // We gon pick out all the relevant cost columns (plus usage_amount + demand_amount).
  // You can adjust or add more columns if you want to chart them too.
  const INITIAL_COLUMNS = [
    'usage_cost',
    'demand_cost',
    'tax_cost',
    'other_applied_cost',
    'other_unapplied_cost',
    'interim_rate_cost',
    'service_cost',
    'total_invoice_cost',
    'usage_amount',
    'demand_amount',
  ]
  const columns = INITIAL_COLUMNS.filter(col => dataRows.some(dr => dr[col] ));

  const dataIncludesDemand = columns.includes("demand_amount")

  // These gon be our x-axis labels (one label per row).
  const labels = dataRows.map(getDateRange);

  const yaxisMap = {
    "usage_amount": "y1",
    "demand_amount": "y2"
  }


  const datasets = columns.map((col, idx) => ({
    label: col,
    data: dataRows.map((row) => row[col] ?? 0),
    // backgroundColor: `hsl(${(idx * 30) % 360}, 70%, 50%)`,
    // borderColor:  `hsl(${(idx * 30) % 360}, 70%, 50%)`,
    backgroundColor: epColors[INITIAL_COLUMNS.findIndex(c => c == columns[idx])],
    borderColor:  epColors[INITIAL_COLUMNS.findIndex(c => c == columns[idx])],
    borderWidth: 1,
    yAxisID: yaxisMap[columns[idx]] || 'y',  
    hidden: "total_invoice_cost" == columns[idx]
  }));

  const chartData = {
    labels,
    datasets,
    dataRows
  };

  function externalTooltipHandler(context) {
    const { chart, tooltip } = context;
    const dataRows = chart.options.plugins.customData.dataRows;
  
    // 1. Get or create tooltip element in the DOM
    let tooltipEl = document.getElementById('chartjs-tooltip');
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.style.backgroundColor = 'white';
      tooltipEl.style.border = '1px solid #bbb';
      tooltipEl.style.borderRadius = '4px';
      tooltipEl.style.padding = '10px';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.pointerEvents = 'auto';  // allow clicking
      tooltipEl.style.zIndex = '9999';
      tooltipEl.style.minWidth = "300px"
      document.body.appendChild(tooltipEl);
    }  
  
    // Hide if no tooltip
    if (tooltip.opacity === 0 || !tooltip.dataPoints || !tooltip.dataPoints.length) {
      tooltipEl.style.opacity = 0;
      console.log("No dataPoints");
      tooltipEl.style.display="none";
      return;
    }
  
    // 2. Find the hovered data index and row
    const dataIndex = tooltip.dataPoints[0].dataIndex;
    if (!dataRows || !dataRows[dataIndex]) {
      tooltipEl.style.opacity = 0;
      console.log("No datarows");
      return;
    }
    const row = dataRows[dataIndex];
    if (!row) return; // safety check

    tooltipEl.style.display="block";
  
    // 3. Build the same URL as your onClick
    const url = `${process.env.REACT_APP_EP_BASE_URL}/admin/utility_accounts/${row.utility_account_id}/utility_bills/new?edit_bill_id=${row.utility_bill_id}`;
  
    // 4. Gather the relevant info to show (invoice date at the top)
    const invoiceDate = row.bill_invoice_date 
      ? new Date(row.bill_invoice_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) 
      : '(No invoice date)';
    // 5. Generate markup for all datasets
    let tooltipContent = `<div style="font-weight: bold; margin-bottom: 4px;">Utility Bill #${row.utility_bill_id}</div>`;
    tooltipContent += `<div style="font-weight: bold; margin-bottom: 4px;">Invoiced ${invoiceDate}</div>`;

    const dateRange = getDateRange(row)

    tooltipContent += `<div style="margin-bottom: 4px;">${dateRange}</div>`;
  
    // Loop through all datasets for this point and format label-value pairs with space-between
    tooltip.dataPoints.forEach((dataPoint, index) => {
      const dataset = chart.data.datasets[dataPoint.datasetIndex];
      let label = dataset.label ? `${dataset.label.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ').replace(/\s*cost|\s*amount/ig, "")}` : '';
      let value = '';

      if (dataset.label === 'usage_amount') {
        tooltipContent += `<hr>`;
        value = `${parseFloat(dataPoint.parsed.y).toLocaleString()} ${row["Measurement Unit"] ?? 'kBtus'}`;
      } else if (dataset.label === 'demand_amount' && dataPoint.parsed.y) {
        value = `${parseFloat(dataPoint.parsed.y).toLocaleString()} kW`;
      } else {
        value = `$${parseFloat(dataPoint.parsed.y).toFixed(2).toLocaleString()}`;
      }

      tooltipContent += `<div style="display: flex; justify-content: space-between;"><span>${label}:</span><span>${value}</span></div>`;
    });
  
    // Add link to open bill
    // tooltipContent += `<div style="margin-top: 8px;"><a href="${url}" target="_blank" style="color: blue; text-decoration: underline;">Open Bill in New Tab</a></div>`;
  
    tooltipEl.innerHTML = tooltipContent;
  
    // 6. Position the tooltip
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';

  }

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  // The "hover mode" and "intersect" go here in Chart.js 3+
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'nearest',
    intersect: false,
    axis: 'x'
  },
  plugins: {
    tooltip: {
      // Turn off default canvas tooltip
      enabled: false,
      external: externalTooltipHandler,
      // position: 'nearest' is still recognized by the new tooltip config
      // but if you want a custom "position" for your external tooltip,
      // you can remove or keep it here (it won't break).
      position: 'nearest',
    },
    customData: {
      dataRows: dataRows
    }
  },
  onClick: (event, elements, chart) => {
    if (elements.length > 0) {
      const elementIndex = elements[0].index;
      const row = dataRows[elementIndex];
      const url = `${process.env.REACT_APP_EP_BASE_URL}/admin/utility_accounts/${row.utility_account_id}/utility_bills/new?edit_bill_id=${row.utility_bill_id}`;
      window.open(url, '_blank');
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      position: 'left',
      title: { 
        display: true,
        text: 'Cost',
        font: {
          size: 12,
        },
      },
    },
    y1: {
      beginAtZero: true,
      type: 'linear',
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
      title: { 
        display: true,
        text: 'Usage',
        font: {
          size: 12,
        },
      },
    },
    ...(dataIncludesDemand ? {
      y2: {
        beginAtZero: true,
        type: 'linear',
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        title: { 
          display: true,
          text: 'Demand',
          font: {
            size: 12,
          },
        },
      }
    } : {})
  }
};
  

  return (
    <Card className={classes.root}>
      <CardContent style={{ height: 600 }}>
        <Line data={chartData} options={chartOptions} />
      </CardContent>
    </Card>
  );
}