import {TableCell, TableRow, TableHead, IconButton} from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add';

export default ({charges, role, handleNewLineItem, isProcessed, uam}) => {

  return (
    <TableHead>
      <TableRow>
        <TableCell style={{fontSize: 24, padding: "8px 16px"}} colSpan={role=="charges" ? 7 : 8}>{
          role == "charges" 
              ? `Charges (Total ${charges.filter(c => c.applicable).reduce((a,c) => (a + +c.amount) || 0, 0).toFixed(2)})`
              : `Usages`}
        </TableCell>
        <TableCell colSpan={2} align='center' style={{padding: "8px 16px"}}>
          <IconButton 
            style={{padding: '10px', margin: '0' }}
            tabIndex={-1} 
            disabled={isProcessed} 
            onClick={_ => handleNewLineItem(role, uam ? uam.id : undefined)}
          >
            <AddIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {!!charges.length && 
        <TableRow>
          <TableCell sx={{ width: '100px' }}>Start Date</TableCell>
          <TableCell sx={{ width: '100px' }}>End Date</TableCell>
          <TableCell sx={{ width: '200px' }}>Description</TableCell>
          <TableCell sx={{ width: '100px' }}>Charge Type</TableCell>
          <TableCell align="center"  sx={{ width: '100px' }}>Amount</TableCell>
          <TableCell align="center"  sx={{ width: '100px' }}>Meter Number</TableCell>
          { role == "usages" && <TableCell align="center" sx={{ width: '40px' }}>Est?</TableCell> }
          <TableCell align="center"  sx={{ width: '200px' }}>UAM</TableCell>
          <TableCell align="center"  sx={{ width: '100px' }}>Include?</TableCell>
        </TableRow>
      }
    </TableHead>
  )
}