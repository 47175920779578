import React, {useEffect, useState, useContext} from 'react';
import {Card, CardContent, Grid, Container, Typography, TableHead, Paper, Table, TableBody, TableCell, TableContainer} from "@material-ui/core";
import {toSnakeCase} from "../../helpers/regex-helpers"
import DateInput from './form/DateInput';
import LineItemDateInput from "./form/LineItemDateInput"
import ClearButton from './ClearButton';
import TextractContext from '../../../contexts/TextractContext';
import InvoyageTooltip from './InvoyageTooltip';
import GPTBillContext from '../../../contexts/GPTBillContext';

const GriddedCard = ({gptBill, textractBill, isDisabled, setGptBill}) => {
  const {currentScaffold} = useContext(TextractContext)
  const {showSavedBill} = useContext(GPTBillContext)


  const [totalCharges, setTotalCharges] = useState({
    total: 0,
    unapplied: 0,
    applied : 0
  })
  const sortConfidence = (a, b) => (b.confidence || 0) - (a.confidence || 0)
  const sortCharges = (a, b) => {
    if (a.response.includes('.') && !b.response.includes('.')) {
        return -1; 
    } else if (!a.response.includes('.') && b.response.includes('.')) {
        return 1; 
    }
        return 0;
  }
  const [total, setTotal] = useState({
    textract: 0,
    gpt: null
  })
  useEffect(_ => {
    let textract, gpt;
    let queryTotal = textractBill.queries.filter(q => q.name == "total_charges" && q.response).sort(sortConfidence).sort(sortCharges)[0]
    if (queryTotal) {
      textract = queryTotal.response
    } if (gptBill["total_cost"]) {
      gpt = gptBill["total_cost"]
    } 

    setTotal({gpt, textract})
  }, [textractBill])

  useEffect(_ => {
    if (gptBill) {
      let applied = 0;
      let unapplied = 0;

      if (!showSavedBill) {
        gptBill.charges.forEach(c => {
          if (c.utility_charge_type_id != 4) {
            applied += (c.applicable ? +c.amount : 0);
          } else {
            unapplied += (c.applicable ? +c.amount : 0);
          }
        });
      } else if (textractBill.ep_bill) {
        textractBill.ep_bill.charges.forEach(c => {
          if (c.utility_charge_type_id != 4) {
            applied += +c.amount;
          } else {
            unapplied += +c.amount;
          }
        });
      }

      setTotalCharges({
        applied,
        unapplied,
        total: unapplied + applied
      });
    }
    
  }, [gptBill, showSavedBill])


  
  const fields = [
    { name: 'Start Date', formatter: _ => <LineItemDateInput disabled={isDisabled} role="start_date" gptBill={showSavedBill ? textractBill.ep_bill : gptBill} /> },
    { name: 'End Date', formatter: _ => <LineItemDateInput disabled={isDisabled} role="end_date" gptBill={showSavedBill ? textractBill.ep_bill : gptBill} />},
    { name: 'Invoice Date', formatter: _ => <DateInput disabled={isDisabled} name="invoice_date" gptBill={gptBill} textractBill={textractBill} /> },
  ];

  const handleClear = e => {
    const scaffoldBill = {...gptBill, ...currentScaffold}
    const emptyLines = (!gptBill.charges.length && !gptBill.usages.length)
    
    return ((currentScaffold && JSON.stringify(gptBill) != JSON.stringify(scaffoldBill)) || emptyLines)
    ? setGptBill(prevState => ({...prevState, ...currentScaffold}) )
    : setGptBill(prevState => ({
      ...prevState,
      charges: [],
      usages: [{utility_usage_type_id: 1, amount: null, start_date: gptBill.start_date, end_date: gptBill.end_date, applicable: true}]
    }))
}


  const possibleStringsDifferenceSpan = (s1, s2) => {
    if (!s2) return <Typography variant='span'>⚠️</Typography>
    s1 = +`${s1}`.replace(/[^\d\.]/g, "")
    s2 = +`${s2}`.replace(/[^\d\.]/g, "")
    if (Number.isNaN(!s1) || Number.isNaN(!s2)) return "?"
    const difference = +s1.toFixed(2) - +s2.toFixed(2)
    if (difference == 0) return <Typography variant='span'>0 ✅</Typography>
    return <Typography variant='span' >{`${difference > 0 ? "+" : "-" }$${Math.abs(difference).toFixed(2)} ⚠️`}</Typography>
  }

  return (
    <TableContainer style={{marginTop: 10}} component={Paper} elevation={3}>
      <Table sx={{ minWidth: 800, '& .MuiTableCell-root': { fontSize: '1rem', padding: "8px 16px" } }} aria-label="Tables Data">
        <TableHead>
          {fields.map((field, index) => (
            <TableCell style={{width: '100px'}}>
                {field.name}
            </TableCell>
          ))}
          <TableCell style={{width: '100px'}}>
              Total Cost (Textract)
          </TableCell>
          <TableCell style={{width: '150px'}}>
              Total Applied Charges
          </TableCell>
          <TableCell style={{width: '150px'}}>
              Difference
          </TableCell>
          <TableCell style={{width: '150px'}}>
              Total Unapplied Charges
          </TableCell>
          <TableCell style={{width: '120px'}}>
              Grand Total
          </TableCell>
          <TableCell>
            {!textractBill.processed && <ClearButton 
              handleClear={handleClear}
            />}
          </TableCell>

        </TableHead>
        <TableBody>
          {fields.map((field, index) => (
            <TableCell>
                {field.formatter(textractBill[toSnakeCase(field.name) || gptBill[toSnakeCase(field.name)]])}
            </TableCell>
          ))}  
          <TableCell>
              {total.textract || ""}
          </TableCell>
          <TableCell>
              ${totalCharges.applied.toFixed(2)}&nbsp;
          </TableCell>
          <TableCell>
            {possibleStringsDifferenceSpan(totalCharges.total.toFixed(2), total.textract)}
          </TableCell>
          <TableCell>
              ${totalCharges.unapplied.toFixed(2)}&nbsp;
              {totalCharges.unapplied != 0 
                && <InvoyageTooltip title="'Other unapplied cost' cannot be reliably validated. Please ensure charges of this type are complete and accurate.">
                  <span>⚠️</span>
                </InvoyageTooltip> }
          </TableCell>
          <TableCell>
              ${totalCharges.total.toFixed(2)}&nbsp;
          </TableCell>
          <TableCell />
        </TableBody>
      </Table>
    </TableContainer>
    // <Container style={{marginLeft: 0, paddingLeft: 0}}>
    //   <Card variant="outlined" style={{ paddingLeft: 0, width: 800, margin: '20px 0px' }}>
    //     <CardContent style={{paddingLeft: 0}} >
    //       <Grid container spacing={0}>
    //         {fields.map((field, index) => (
    //           <Grid key={index} item xs={6} sm={3}>
    //               <Typography>{field.name}</Typography>
    //               <Typography>{field.formatter(gptBill[toSnakeCase(field.name)] || textractBill[toSnakeCase(field.name)])}</Typography>
    //           </Grid>
    //         ))}
    //       </Grid>
    //     </CardContent>
    //   </Card>
    // </Container>
  );
}

export default GriddedCard;