import {useState, useEffect, useContext} from "react";
import "./RequestEmail.scss";
import axiosAuth from "../../utils/axiosWithAuth";
import RequestListGrid from "./RequestListGrid";
import {Snackbar, Alert, ListSubheader, TextField, MenuItem, AppBar, Tabs, Tab, InputLabel, Select, makeStyles} from "@material-ui/core"
import TabPanel from "./TabPanel"
import {a11yProps} from "../helpers/a11y"
import RequestListContext from "../../contexts/RequestListContext";
import Loader from "react-loader-spinner";
import UserContext from "../../contexts/UserContext"
import EmailTemplateForm from "./EmailTemplates"
import {getFormattedDate, requestHasAlarm} from "../helpers/date-helpers"
import moment from 'moment'
import {seo} from "../helpers/seo"
import { copyToClipboard } from "../helpers/string-helpers"

import {emailRegex} from "../helpers/regex-helpers"

export default function RequestEmail() {
    const {selectedTemplate, setSelectedTemplate} = useContext(UserContext)
    const [selectedRequest, setSelectedRequest] = useState(null)
    const [openGlobalSnackbar, setOpenGlobalSnackbar] = useState(false)
    const [editEmailInitial, setEditEmailInitial] = useState({
        to: "",
        subject: "",
        body: "",
        attachments: [],
        excludedAttachments: [],
    })
    const [globalSnackbarMessage, setGlobalSnackbarMessage] = useState("")
    const [globalSnackbarMode, setGlobalSnackbarMode] = useState("success")
    const [requestList, setRequestList] = useState([])
    const [fullRequestList, setFullRequestList] = useState([])
    const [dateVisible, setDateVisible] = useState([])
    const [tabIndex, setTabIndex] = useState(0)
    const [viewingIssues, setViewingIssues] = useState(false)
    const [sentReceivedList, setSentReceivedList] = useState([])
    const [followUpList, setFollowUpList] = useState([])
    const [selectedRequestList, setSelectedRequestList] = useState("all_requests")
    const [issues, setIssues] = useState([])
    const [onboardings, setOnboardings] = useState([])
    const [loading, setLoading] = useState(false)
    const [templates, setTemplates] = useState([])
    const [groupedTemplates, setGroupedTemplates] = useState([<ListSubheader>Loading...</ListSubheader>])
    const [authLetters,  setAuthLetters] = useState([])
    const [openNotesModal, setOpenNotesModal] = useState(false)
    const [completeList, setCompleteList] = useState([])
    const [openModal, setOpenModal] = useState(false)

    const INITIAL_TEMPLATE = {
        Ongoing: [],
        Issue: [],
        Onboarding: [],
        Hive: []
    }

    const OPEN_TAB_OMITTED_DELIVERY_METHODS = ["Emailed", "Emailed Quarterly", "Emailed Bi-monthly", "ARDEM Emailed", "ARDEM Emailed Quarterly", "ARDEM Emailed Bi-monthly"]

    const useStyles= makeStyles(theme => ({
        paper: {
            position: 'absolute',
            width: '50%',
            backgroundColor: 'white',
            border: '2px solid black',
            boxShadow: "2px 2px 2px #888888",
            padding: theme.spacing(2,4,3),
            top: `${5}%`,
            left: `${50}%`,
            transform: `translate(-${50}%, 0%)`
        },
        templateSelect: {
            width: 400, 
            marginLeft: 5, 
            textAlign: 'left', 
            padding: 5
        }
    }))


    useEffect(fetchIssues, [])

    function fetchIssues() {
        axiosAuth().get("/admin/utility_email_requests/issues/")
            .then(r => {
                console.log("issues", r.data.requests)
                setIssues(r.data.requests.issues)
                setOnboardings(r.data.requests.onboardings)
            })
            .catch(err => console.log(err.response))
    }

    useEffect(_ => {
        const h = {...INITIAL_TEMPLATE}
        console.log("templates", templates)
        templates.forEach((t) => h[t.template_type].push(t))
        console.log(h)
        const templateSelect = []
        Object.keys(h).forEach(key => {
            templateSelect.push(<ListSubheader style={{fontSize: "1.25rem"}}>{key == "Issue" ? "On-Issue" : key}</ListSubheader>)
            h[key].forEach(t => {
                templateSelect.push(<MenuItem key={"template-" + t.id} value={t.id}>{t.name}</MenuItem>)
            })
        })
        setGroupedTemplates(templateSelect)

    }, [templates])
    

    useEffect(_ => {
        fetchRequestList(selectedRequestList)
    },[selectedRequestList])

    useEffect(_ => {
        axiosAuth().post("/admin/utility_email_requests/authorization_letters/", selectedRequest)
            .then(r => setAuthLetters(r.data.letters))
            .catch(err => console.log(err.response))
    }, [selectedRequest])

    useEffect( _ => {
        seo({
            title: 'Request Emailer'
          });
        axiosAuth().get("/admin/utility_email_requests/templates")
        .then(r => {
            console.log(r.data.templates)
            setTemplates(r.data.templates.sort(( a, b) => a.name.localeCompare(b.name) ))
         })
        .catch(err => console.log(err.response))
    },[])


    useEffect(_ => {
        if (!selectedTemplate.name && templates && templates[0]  ) setSelectedTemplate(templates[0])
    },[templates])

    const fetchRequestList = listName => {
        setLoading(true)
        axiosAuth().get(`/admin/utility_email_requests/${listName}`)
        .then(listR => {
            setFullRequestList(listR.data.requests)
            axiosAuth().get(`/admin/utility_email_requests/alltab`)
            .then(alltabR => {
                let newList = alltabR.data.requests;
                if (listName.includes("no_loa")) {
                    newList = newList.filter(r => !r.loa_required)
                } else if (listName.includes("has_delivery_name")) {
                    newList = newList.filter(r => r.delivery_name )
                } else if (listName.includes("loa_required")) {
                    newList = newList.filter(r => r.loa_required)
                } 
                setCompleteList(newList)
                setLoading(false)
            }).catch(console.log)
        })
        .catch(err => console.log(err))
    }



    useEffect(_ => {
        setSentReceivedList(completeList.filter(r => r.last_sent_date || r.last_received_date))
        setFollowUpList(completeList.filter(r => r.follow_up_date))
        setRequestList(fullRequestList.filter(r => !OPEN_TAB_OMITTED_DELIVERY_METHODS.includes(r.delivery_method) && ((!r.last_sent_date 
                                                    && !r.last_received_date 
                                                    && !r.follow_up_date) 
                                                || ( r.follow_up_date 
                                                    && new Date(r.follow_up_date) <= new Date() ) 
                                                || (requestHasAlarm(r)))
                        ))
    }, [fullRequestList, completeList])



    const equalRequests = (r1, r2) => ["acct_num", "latest_invoice_date"].every(key => r1[key] === r2[key]) 
    
    
    const markAsSent = (request,complete, issue="") => {
        axiosAuth().post("/admin/utility_email_requests/mark_as_sent", {request, complete, issue})
        .then(res => {
            if (fullRequestList.find(r => equalRequests(request, r))) {
                setFullRequestList([...fullRequestList.filter(r =>  !equalRequests(request, r)), res.data.sent_request])
            }
            if (completeList.find(r => equalRequests(request, r))) {
                setCompleteList([...completeList.filter(r =>  !equalRequests(request, r)), res.data.sent_request])
            }
            if (issues.find(i => equalRequests(request, i))) {
                setIssues([...issues.filter(r =>  !equalRequests(request, r)), res.data.sent_request])
            }
            if (onboardings.find(i => equalRequests(request, i))) {
                setOnboardings([...onboardings.filter(r =>  !equalRequests(request, r)), res.data.sent_request])
            }
        })
        .catch(err => console.log(err))
    }
    const markAsReceived = (request, complete) => {
        axiosAuth().post("/admin/utility_email_requests/mark_as_received", {request, complete})
        .then(res => {
            request.last_received_date = res.data.request.last_received_date
            if (fullRequestList.find(r => equalRequests(request, r))) {
                setFullRequestList([...fullRequestList.filter(r =>  !equalRequests(request, r)), request])
            }
            if (completeList.find(r => equalRequests(request, r))) {
                setCompleteList([...completeList.filter(r =>  !equalRequests(request, r)), request])
            }
            if (issues.find(i => equalRequests(request, i))) {
                setIssues([...issues.filter(r =>  !equalRequests(request, r)), request])
            }
            if (onboardings.find(i => equalRequests(request, i))) {
                setOnboardings([...onboardings.filter(r =>  !equalRequests(request, r)), request])
            }
        })
        .catch(err => console.log(err))
    }

    function handleAutomationToggle(request) {
        const setting = viewingIssues ? !request.exclude_from_automated_issues : !request.exclude_from_automated_requests
        
        const body = {
            issue: viewingIssues,
            setting,
            acctId: request.utility_account_id
        }
        console.log(body)
        axiosAuth().post("/admin/utility_email_requests/update-automation", body)
        .then(response => {
            console.log(response)
            console.log(`exclude_from_automated_${viewingIssues ? "issues": "requests"}`)
            const newRequest = {...request, [`exclude_from_automated_${viewingIssues ? "issues": "requests"}`]: setting}
            console.log("newRequest", newRequest)
            if (fullRequestList.find(r => equalRequests(request, r))) {
                setFullRequestList([...fullRequestList.filter(r =>  !equalRequests(request, r)), newRequest])
            }
            if (completeList.find(r => equalRequests(request, r))) {
                setCompleteList([...completeList.filter(r =>  !equalRequests(request, r)), newRequest])
            }
            if (issues.find(i => equalRequests(request, i))) {
                setIssues([...issues.filter(r =>  !equalRequests(request, r)), newRequest])
            }
            if (onboardings.find(i => equalRequests(request, i))) {
                setOnboardings([...onboardings.filter(r =>  !equalRequests(request, r)), newRequest])
            }
        })
        .catch(e => console.log(e))
    }
    
    const setFollowUpDate = (r, date) => {
        axiosAuth().post("/admin/utility_email_requests/set_follow_up_date", {request: r, date})
        .then(r => console.log(r))
        .catch(err => console.log(err.response))
        if (fullRequestList.find(req => equalRequests(r, req))) {
            setFullRequestList([...fullRequestList.filter(req => !equalRequests(req, r) ), {...r, follow_up_date: date}])
        }
        if (completeList.find(req => equalRequests(r, req))) {
            setCompleteList([...completeList.filter(req =>  !equalRequests(req, r)), {...r, follow_up_date: date}])
        }
        if (issues.find(i => equalRequests(r, i))) {
            setIssues([...issues.filter(i =>  !equalRequests(r, i)), {...r, follow_up_date: date}])
        }
        if (onboardings.find(i => equalRequests(r, i))) {
            setOnboardings([...onboardings.filter(i =>  !equalRequests(r, i)), {...r, follow_up_date: date}])
        }
    }

    const handleMailToClick = (e, request, template, isIssue, useTemplateLogic, forModal) => {
        e.preventDefault()

        axiosAuth().post("/admin/utility_email_requests/build_email", {request, template, isIssue, useTemplateLogic})
        .then(r => {
            console.log(r)
            let {recipient, newSubject, newBody, attachments, isIssue, traits, accounts} = r.data;
            if (!recipient) {
                setOpenGlobalSnackbar(true)
                setGlobalSnackbarMode("info")
                setGlobalSnackbarMessage(r.data.message || "Something went wrong. No data was returned.")
                return
            }
            setEditEmailInitial({...editEmailInitial, 
                body: newBody, 
                to: recipient, 
                subject: newSubject, 
                attachments: attachments || [], 
                isIssue, traits, accounts})
            const isFirefox = typeof InstallTrigger !== 'undefined';
            if (forModal) {
                setOpenModal(true)
                return
            }
            if (!isFirefox) {
                if (`mailto:${recipient}?subject=${encodeURIComponent(newSubject)}&body=${encodeURIComponent(newBody)}`.length >= 1900) {
                    copyToClipboard(newBody)
                    newBody = "** Press Ctrl-A, Ctrl-V to paste large email message **"
                }
            }
            window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(newSubject)}&body=${encodeURIComponent(newBody)}`
            
        }).catch(console.error)
    }
    
    const setEmailTemplateId = (r, email_template_id)=> {
        console.log(r, email_template_id)
        const req = {
            email_template_id,
            utility_account_id: r.utility_account_id, 
        }
        axiosAuth().put("/admin/utility_email_requests/settemplateid", req)
        .then(console.log).catch(console.error)

        setFullRequestList([...fullRequestList.filter(req => !equalRequests(req, r) ), {...r, email_template_id}])
        setCompleteList([...completeList.filter(req =>  !equalRequests(req, r)), {...r, email_template_id}])
    }

    const setRequestOnWeek = (r, n)=> {

        const req = {
            request: r,
            request_on_week: n
        }
        axiosAuth().put("/admin/utility_email_requests/setRequestOnWeek", req)
        .then(console.log).catch(console.error)

        setFullRequestList([...fullRequestList.filter(req => !equalRequests(req, r) ), {...r, request_on_week: n}])
        setCompleteList([...completeList.filter(req =>  !equalRequests(req, r)), {...r, request_on_week: n}])
    }
    
    const handleDateRemoval = r => {
        setFollowUpDate(r, null)
        setDateVisible(dateVisible.filter(id => id !== r.id))
    }

    const sortByCompanyName = arr => {
        return arr.sort((a, b) => {
                const textA = a.utility_company_name.toUpperCase() + a.acct_num.toUpperCase()
                const textB = b.utility_company_name.toUpperCase() + b.acct_num.toUpperCase()
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
    }

    const handleTemplateChange = e => {
        setSelectedTemplate(templates.find(t => t.id === +e.target.value))
    }

    useEffect(_ => {
        setViewingIssues(tabIndex == 4 ? true : false)
    },[tabIndex])

    useEffect(_ => {
        if (selectedRequest) {
            setSelectedRequest(completeList.find(r => r.id == selectedRequest.id) || issues.find(r => r.id == selectedRequest.id))
        }
    }, [fullRequestList, completeList, issues])
    const styles=useStyles()
    return (
        <RequestListContext.Provider value={{fetchIssues, fetchRequestList, selectedRequestList, openModal, setOpenModal, editEmailInitial, setEditEmailInitial, viewingIssues, groupedTemplates, setRequestOnWeek, handleAutomationToggle, openNotesModal, setOpenNotesModal, authLetters, selectedRequest, setSelectedRequest, templates, setTemplates, handleMailToClick, markAsSent, markAsReceived, setFollowUpDate, handleDateRemoval, selectedTemplate, setEmailTemplateId }}>

            <AppBar position="static" color="default">
            <h1>Utility Request List</h1>
            <div className="select-container">
                <InputLabel htmlFor="request-list-select">Request Type:</InputLabel>
                <Select style={{height: 40, textAlign: 'left', width: 200, margin: "0px 10px" }}
                        value={selectedRequestList}
                        onChange={e => setSelectedRequestList(e.target.value)}
                        name="request-list-select"
                        label="Request Type"                        
                >
                

                    <MenuItem value="all_requests">All</MenuItem>
                    <MenuItem value="no_loa_no_delivery_name">No LOAs, No Contact</MenuItem>
                    <MenuItem value="has_delivery_name">Has Delivery Name</MenuItem>
                    <MenuItem value="loa_required">Requires LOA</MenuItem>
                </Select>
                <InputLabel style={{marginLeft: 20}}  htmlFor="request-list-select">Selected Template:</InputLabel>

                <Select
                    name="main-template-select"
                    label="Template"
                    variant='standard'
                    className={styles.templateSelect}
                    onChange={handleTemplateChange} 
                    value={selectedTemplate.id || ""}>
                    {groupedTemplates}
                </Select>
                {loading && <Loader
                    type="Oval"
                    color="orange"
                    height={40}
                    width={40} />}
            </div>
                <Tabs   
                    centered 
                    indicatorColor="primary" 
                    value={tabIndex} 
                    onChange={(e, val) => setTabIndex(val)}>
                    <Tab label="All" {...a11yProps(0)} />
                    <Tab label="Open" {...a11yProps(1)} />
                    <Tab label="Sent/Received" {...a11yProps(2)} />
                    <Tab label="Follow Up" {...a11yProps(3)} />
                    <Tab label="On Issue" {...a11yProps(4)} />
                    <Tab label="Onboarding" {...a11yProps(5)} />
                    <Tab label="Manage Templates" {...a11yProps(6)} />
                </Tabs>
            </AppBar>

            <TabPanel value={tabIndex} index={0}>
                <RequestListGrid requests={sortByCompanyName(completeList)} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <RequestListGrid requests={sortByCompanyName(requestList)} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <RequestListGrid requests={sortByCompanyName(sentReceivedList)} />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
                <RequestListGrid requests={sortByCompanyName(followUpList)} />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
                <RequestListGrid requests={sortByCompanyName(issues)} issue={true} />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
                <RequestListGrid requests={sortByCompanyName(onboardings)} />
            </TabPanel>
            <TabPanel value={tabIndex} index={6}>
                <EmailTemplateForm />
            </TabPanel>
            
            <Snackbar 
                anchorOrigin={{ vertical:"top", horizontal: "right" }}
                open={openGlobalSnackbar} 
                autoHideDuration={6000} 
                onClose={_ => setOpenGlobalSnackbar(false)}>
                <Alert onClose={_ => setOpenGlobalSnackbar(false)} severity={globalSnackbarMode}>
                    {globalSnackbarMessage}
                </Alert>
            </Snackbar>
            
        </RequestListContext.Provider>
    )
}