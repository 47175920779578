import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

function ConfettiOnClick() {
  useEffect(() => {
    // Define all holidays in one place:
    const holidays = [
      {
        name: "Christmas Season",
        check: (today) => {
          const year = today.getFullYear();
          const christmas = new Date(year, 11, 25); // Dec 25
          const newYears = new Date(year + 1, 0, 2); // Jan 1 next year
          return today >= christmas && today < newYears;
        },
        colors: ['#165B33', '#146B3A', '#F8B229', '#EA4630', '#BB2528']
      },
      {
        name: "4th of July",
        check: (today) => {
          const year = today.getFullYear();
          const start = new Date(year, 6, 3); // Dec 25
          const end = new Date(year, 6, 5); // Jan 1 next year
          return today >= start && today < end;
        },
        colors: ['#FF0000', '#FFFFFF', '#0000FF']
      },
      {
        name: "Halloween",
        check: (today) => {
          // Checking for *just* October 31. 
          // If you want the entire spooky season, you can expand dis logic. 
          const year = today.getFullYear();
          const halloween = new Date(year, 9, 31); // October 31
          return (
            today.getDate() === halloween.getDate() && 
            today.getMonth() === halloween.getMonth()
          );
        },
        colors: ["#000000", "#FFA500"] // black & orange
      },
      {
        name: "Valentine's Day",
        check: (today) => {
          const year = today.getFullYear();
          const valentines = new Date(year, 1, 14); // Feb 14
          return today.getDate() === valentines.getDate() && 
                 today.getMonth() === valentines.getMonth();
        },
        colors: ["#FF69B4", "#FFC0CB", "#FFD700"]
      },
    ];

    const today = new Date();

    // Find if any holiday's 'check' function says we’re in that holiday
    const activeHoliday = holidays.find((holiday) => holiday.check(today));

    // If we found a holiday, wire up the confetti logic
    if (activeHoliday) {
      function handleClick(event) {
        confetti({
          colors: activeHoliday.colors,
          angle: randomInRange(55, 125),
          spread: randomInRange(20, 50),
          particleCount: randomInRange(5, 10),
          startVelocity: 25,
          origin: {
            x: event.clientX / window.innerWidth,
            y: event.clientY / window.innerHeight,
          },
        });
      }

      window.addEventListener('click', handleClick);

      // Cleanup on unmount
      return () => {
        window.removeEventListener('click', handleClick);
      };
    }
  }, []);

  return null;
}

export default ConfettiOnClick;